<template lang="">
  <div>
    Login with Microsoft
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      // this.$refs.form.validate();
      // if (!this.valid) return;
      // this.loading = true;
      const role = this.$route.params.role;
      const origin = location.origin
      this.$store
        .dispatch("user/loginWithMsal", { code: this.$route.query.code, role, origin })
        .then(res => {
          window.location.href = "/" + role;
        })
        .catch(error => {
          window.location.href = "/";
        });
    }
  },
  created() {
    this.login();
  }
};
</script>